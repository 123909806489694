import { FieldPreset } from '../../constants/field-types'
import { SuccessActionTypes } from '../../constants/success-settings'
import { FormPreset } from '../../constants/form-types'
import { FormPlugin } from '../../constants/plugins'
import { Theme } from '../../constants/form-style'
import { COMPONENT_TYPES } from './preset/fields/component-types'

export type FieldPlaceholder = string | { text: string; value: any }
export enum FileType {
  IMAGE = "Image",
  DOCUMNET = "Document"
}

export enum OptionType {
  SELECT_OPTION = 'SelectOption',
  RADIO_BUTTON = 'RadioButton',
  CHECKBOX_GROUP = 'CheckboxInput'
}

export interface RadioOption {
  label: string
  value: any,
  type: OptionType.RADIO_BUTTON
}

export interface CheckboxOption {
  label: string,
  value: any,
  checked: boolean,
  type: OptionType.CHECKBOX_GROUP
}

export interface DropdownOption {
  value: any
  text: string
  description: string
  disabled: boolean
  type: OptionType.SELECT_OPTION
}

export type FieldOption = RadioOption | DropdownOption | CheckboxOption

export interface FormField {
  componentRef: ComponentRef
  crmLabel: string
  required: boolean
  crmType: string
  crmTag: string
  fieldType: FieldPreset
  customFieldId?: string
  collectionFieldKey?: string
  collectionFieldType?: string
  checked?: boolean
  role: string
  label: string
  placeholder: FieldPlaceholder
  showLabel: boolean
  buttonLabel?: string
  x?: number
  y?: number
  height?: number
  width?: number
  dateFormat?: string
  options?: FieldOption[]
  defaultValue?: string,
  filesType?: FileType,
  showPlaceholder?: boolean
}

export interface FormFieldDefinition {
  fieldId: string
  fieldName: string
  fieldCollectionType?: string
  fieldCollectionKey?: string
}

export type ComponentRef = {
  id: string
  type: string
}

export type formComponent = {
  presetKey: string
  rawComp: any
  theme?: Theme
  newSuccessMessage?: string
}

export type Plugin = {
  id: FormPlugin
}

export interface ProductData {
  productId: string
  name: string
  price: string
}

export type ComponentConfig = {
  formName: string
  preset: FormPreset
  fieldType: FieldPreset
  plugins?: Plugin[]
  collectionId?: string
  successActionType: SuccessActionTypes
  labels: string[]
  successLinkValue: any
  theme?: Theme
  emailId?: string
  secondEmailId?: string
  formLabelId?: string
  collectionFieldKey?: string
  crmLabel: string
  crmType: string
  crmTag: string
  customFieldId?: string
  collectionFieldType?: string
  label: string
  product?: ProductData
}

export type ComponentConnection = {
  role: string
  config: ComponentConfig
  controllerRef: ComponentRef
  isPrimary: boolean
}

export type ColorPalette = {
  colorsPalette: Array<Array<string>>
  colors: object
}

export enum CriticalComponentTypes {
  SUBMIT_BUTTON = 'submitButton',
  SIGNUP_BUTTON = 'signupButton',
  HIDDEN_MESSAGE = 'hiddenMessage',
  REGISTRATION_FORM_MESSAGE = 'registrationFormMessage',
  EMAIL_FIELD = 'emailField',
}

export type FormSnapshot = {
  components: ComponentSnapshot[]
  config: ComponentConfig
}

export type ComponentSnapshot = {
  role: string
  config: ComponentConfig
  data: any
  props: any
  layout: any
  componentRef: ComponentRef
  style: any
  componentType: COMPONENT_TYPES
  components: ComponentSnapshot[] | undefined
  skin: string
}
