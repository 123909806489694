import * as _ from 'lodash'
import { FieldPreset } from '../../../../../../constants/field-types'
import translations from '../../../../services/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { firstNameData } from '../../recommended-fields/definitions/first-name'

const makeAdiFirstName = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return _.merge(firstNameData.make(), {
    extraData: {
      props: {
        placeholder: t(`adi.fieldTypes.${FieldPreset.FIRST_NAME}.placeholder`),
        required: true,
      },
      data: {
        placeholder: t(`adi.fieldTypes.${FieldPreset.FIRST_NAME}.placeholder`),
        label: t(`adi.fieldTypes.${FieldPreset.FIRST_NAME}.label`),
      },
    },
  })
}

export const adiFirstNameData: FieldData = {
  make: makeAdiFirstName,
  customFields: firstNameData.customFields,
  icon: firstNameData.icon,
}
