import * as _ from 'lodash'
import { customTypes, FieldPreset } from '../../../../../../constants/field-types'
import translations from '../../../../services/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { iconNames } from '../../icons/types'
import { generalDatePickerData } from '../../general-fields/definitions/general-date-picker'

const makeAdiDate = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return _.merge(generalDatePickerData.make(), {
    extraData: {
      data: {
        placeholder: t(`adi.fieldTypes.${FieldPreset.GENERAL_DATE_PICKER}.placeholder`),
        label: t(`adi.fieldTypes.${FieldPreset.GENERAL_DATE_PICKER}.label`),
      },
      props: { placeholder: t(`adi.fieldTypes.${FieldPreset.GENERAL_DATE_PICKER}.placeholder`) },
    },
  })
}

export const adiDateData: FieldData = {
  make: makeAdiDate,
  customFields: [customTypes.DATE],
  icon: iconNames.date,
}
