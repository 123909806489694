import { undoable, withBi } from '../utils'
import { EVENTS } from '../../../constants/bi'
import { ComponentConnection, ComponentRef } from '../api-types'
import CoreApi from '../core-api'
import { SuccessActionTypes } from '../../../constants/success-settings'
import { ROLE_FORM, ROLE_MESSAGE, ROLE_SUBMIT_BUTTON } from '../../../constants/roles'
import {
  createHiddenMessage,
  createSubmitButton,
  getExtraMessageText,
} from '../services/form-service'
import { LinkTypes, mediaTypes } from '../../../panels/settings-panel/constants'
import { HIDDEN_MESSAGE, MESSAGE_EXTRA_HEIGHT, SUBMIT } from './consts/heights'
import { innerText } from '../../../utils/utils'
import * as _ from 'lodash'
import { ButtonType, MessageType } from '../../../constants/field-types'
import {
  LinkPanelTypes,
  MediaManagerUploadedObjectResponse,
  UploadStatuses,
  DEFAULT_EXTERNAL_LINK_OBJECT,
  DEFAULT_LINK_OBJECT,
  DEFAULT_UPLOAD_OBJECT,
  VISIBLE_LINK_PANEL_SECTIONS,
  LinkPanelTypesToActionTypes,
  VISIBLE_LINK_PANEL_SECTIONS_ADI,
} from './consts/links-settings'
import RemoteApi from '../../../panels/commons/remote-api'
import { FormPreset } from '../../../constants/form-types'
import { Theme } from '../../../constants/form-style'
import { PremiumRestriction } from '../../../constants/premium'

export interface InitialSettingsPanelData {
  message?: string
  links?: any
  email?: string
  secondEmail?: string
  isSubmitButtonExists?: boolean
  isHiddenMessageExists?: boolean
  isEmailFieldMissing?: boolean
  otherFormsNames?: string[]
  isCollectionExists?: boolean
  isRegistrationForm?: boolean
  restrictions?: PremiumRestriction
  productName?: string
  productPrice?: string
}

export default class SettingsApi {
  private biLogger: any
  private experiments: any
  private boundEditorSDK: any
  private coreApi: CoreApi
  private remoteApi: RemoteApi
  private editorSDK: any

  constructor(boundEditorSDK, editorSDK, coreApi: CoreApi, remoteApi, { biLogger, experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.remoteApi = remoteApi
    this.editorSDK = editorSDK
    this.experiments = experiments
  }

  public async loadInitialPanelData(componentRef): Promise<InitialSettingsPanelData> {
    return Promise.all([
      this.getMessage(componentRef),
      this.getSubmitOptionsData(componentRef),
      this.getEmails(componentRef),
      this.getCrucialElements(componentRef),
      this.getOtherFormsNames(componentRef),
      this.coreApi.isCollectionExists(componentRef),
      this.coreApi.isRegistrationForm(componentRef),
      this.coreApi.premium.getPremiumRestrictions(),
      this.getProductData(componentRef)
    ]).then(([
      { message },
      links,
      [emailObj, secondEmailObj],
      { isSubmitButtonExists, isHiddenMessageExists, isEmailFieldMissing },
      otherFormsNames,
      isCollectionExists,
      isRegistrationForm,
      { restrictions },
      { name, price }
    ]) => ({
      message,
      links,
      email: emailObj ? emailObj.email : '',
      secondEmail: secondEmailObj ? secondEmailObj.email : '',
      isSubmitButtonExists,
      isHiddenMessageExists,
      isEmailFieldMissing,
      otherFormsNames,
      isCollectionExists,
      isRegistrationForm,
      restrictions,
      productName: name,
      productPrice: price
    }))
  }

  @undoable()
  @withBi({
    startEvid: EVENTS.PANELS.settingsPanel.CREATE_SUBMISSIONS_TABLE,
    endEvid: EVENTS.PANELS.settingsPanel.SUBMISSIONS_TABLE_CREATED_SUCCESSFULLY,
  })

  public async createCollection(componentRef: ComponentRef, _biData = {}): Promise<string> {
    return this.coreApi.createCollection(componentRef)
  }

  @undoable()
  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public setComponentConnection(connectToRef: ComponentRef, connectionConfig, _biData = {}) {
    return this.coreApi.setComponentConnection(connectToRef, connectionConfig)
  }

  @undoable()
  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public async setLabels(connectToRef: ComponentRef, labels: string[], _biData = {}) {
    const { controllerRef, role, config } = await this.coreApi.getComponentConnection(connectToRef)
    config.labels = labels

    return this.boundEditorSDK.controllers.connect({
      connectToRef,
      controllerRef,
      role,
      connectionConfig: config,
      isPrimary: true,
    })
  }

  public async createProduct(componentRef: ComponentRef, name: string, price: string) {
    const productPrice = Number(price)
    const currency = await this.boundEditorSDK.info.getCurrency()

    if (_.isEmpty(name) || !(productPrice >= 0)) {
      return
    }

    const productPayload = {
      currency,
      name,
      price: productPrice,
      productData: {
        'form-id': componentRef.id,
      },
    }

    const { productId } = await this.remoteApi.createProduct({
      product: productPayload,
    })

    this.setComponentConnection(componentRef, {
      product: { id: productId, name, price: productPrice },
    })
  }

  public async getProductData(componentRef) {
    const {
      config: { product },
    } = await this.coreApi.getComponentConnection(componentRef)
    const name = _.get(product, 'name')
    const price = _.get(product, 'price')
    return { name, price }
  }

  @undoable()
  public async setSuccessActionType(
    connectToRef: ComponentRef,
    successActionType: SuccessActionTypes,
    successLinkValue,
    newSuccessMessage?
  ) {
    if (successActionType === SuccessActionTypes.SHOW_MESSAGE) {
      await this._addHiddenMessage(connectToRef, newSuccessMessage)
    } else {
      this.removeSuccessMessage(connectToRef)
    }
    await this.coreApi.setComponentConnection(
      connectToRef,
      { successActionType, successLinkValue },
      false
    )
  }

  @undoable()
  public addHiddenMessage(componentRef: ComponentRef) {
    return this._addHiddenMessage(componentRef)
  }

  public async removeSuccessMessage(componentRef: ComponentRef) {
    const get = async () => {
      const messageRef = await this.coreApi.findComponentByRole(componentRef, ROLE_MESSAGE)
      const messageLayout = await this.boundEditorSDK.components.layout.get({
        componentRef: messageRef,
      })
      const boxLayout = await this.boundEditorSDK.components.layout.get({ componentRef })
      return { messageRef, messageLayout, boxLayout }
    }
    const update = async (boxLayout, messageLayout) => {
      await this.boundEditorSDK.components.layout.update({
        componentRef,
        layout: { height: boxLayout.height - (messageLayout.height + MESSAGE_EXTRA_HEIGHT) },
      })
    }

    const { messageRef, messageLayout, boxLayout } = await get()
    if (!boxLayout || !messageLayout) {
      return
    }
    await update(boxLayout, messageLayout)
    return this.coreApi.removeComponentRef(messageRef)
  }

  @undoable()
  public async handleSuccessLinkPanel(componentRef: ComponentRef, isADI = false) {
    const {
      config: { successLinkValue },
    } = await this.coreApi.getComponentConnection(componentRef)

    const linkObject = await this.boundEditorSDK.editor.openLinkPanel({
      value: successLinkValue,
      visibleSections: isADI ? VISIBLE_LINK_PANEL_SECTIONS_ADI : VISIBLE_LINK_PANEL_SECTIONS,
    })

    const successLinkText = await this.boundEditorSDK.editor.utils.getLinkAsString({
      link: linkObject,
    })

    const chosenLinkType = linkObject && linkObject.type ? linkObject.type : LinkPanelTypes.NONE
    await this.setSuccessActionType(
      componentRef,
      LinkPanelTypesToActionTypes[chosenLinkType],
      linkObject
    )

    const successLinkType = await this._getLinkType(linkObject)
    const successLinkSubType = this._getLinkSubType(successLinkText, successLinkType, linkObject)

    return {
      chosenLinkType: LinkPanelTypesToActionTypes[chosenLinkType],
      successLinkText,
      linkObject,
      successLinkType,
      successLinkSubType,
    }
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.settingsPanel.CLICK_UPLOAD_BUTTON })
  public async updateDownloadSection(componentRef: ComponentRef, _biData) {
    let {
      config: { successLinkValue },
    } = await this.coreApi.getComponentConnection(componentRef)

    try {
      const uploadedObject: Array<
        MediaManagerUploadedObjectResponse
      > = await this.boundEditorSDK.editor.openMediaPanel({
        mediaType: mediaTypes.DOCUMENT,
        isMultiSelect: false,
      })

      if (uploadedObject) {
        successLinkValue.docId = _.head(uploadedObject).uri
        successLinkValue.name = _.head(uploadedObject).title
        successLinkValue.status = UploadStatuses.UPLOAD_SUCCESS
      }
    } catch (error) {
      successLinkValue.status = UploadStatuses.UPLOAD_FAILED
    }
    await this.setComponentConnection(
      componentRef,
      {
        successLinkValue: successLinkValue,
      },
      false
    )
    return successLinkValue
  }

  public async getSubmitOptionsData(componentRef) {
    let {
      config: { successActionType, successLinkValue },
    } = await this.coreApi.getComponentConnection(componentRef)

    const getText = linkObj => this.boundEditorSDK.editor.utils.getLinkAsString({ link: linkObj })

    const links = {
      [SuccessActionTypes.LINK]: {
        text: await getText(DEFAULT_LINK_OBJECT),
        object: DEFAULT_LINK_OBJECT,
      },
      [SuccessActionTypes.EXTERNAL_LINK]: {
        text: await getText(DEFAULT_EXTERNAL_LINK_OBJECT),
        object: DEFAULT_EXTERNAL_LINK_OBJECT,
      },
      [SuccessActionTypes.DOWNLOAD_DOCUMENT]: {
        object: DEFAULT_UPLOAD_OBJECT,
      },
    }

    if (successActionType !== SuccessActionTypes.SHOW_MESSAGE) {
      links[successActionType] = {
        text: await getText(successLinkValue),
        object: successLinkValue,
      }
    }

    return links
  }

  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public async setEmail(componentRef, emailId, email, _biData = {}) {
    const { id } = await this.remoteApi.insertEmail(email)
    return this.setComponentConnection(componentRef, { [emailId]: id })
  }

  @withBi({
    startEvid: EVENTS.PANELS.settingsPanel.SUCCESS_ACTION_TYPE_SELECTED,
    endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED,
  })
  public async updateMessage(componentRef: ComponentRef, newSuccessMessage, _biData = {}) {
    const messageRef = await this.coreApi.findComponentByRole(componentRef, ROLE_MESSAGE)
    const data = await this.boundEditorSDK.components.data.get({ componentRef: messageRef })

    return this.boundEditorSDK.components.data.update({
      componentRef: messageRef,
      data: getExtraMessageText({ data, newSuccessMessage }),
    })
  }

  public async getSubmitButtonLabel(componentRef: ComponentRef) {
    const submitButtonRef = await this.coreApi.findComponentByRole(componentRef, ROLE_SUBMIT_BUTTON)
    return this.coreApi.getButtonLabel(submitButtonRef)
  }

  public async updateSubmitButtonLabel(componentRef: ComponentRef, newLabel: string) {
    const submitButtonRef = await this.coreApi.findComponentByRole(componentRef, ROLE_SUBMIT_BUTTON)
    return this.coreApi.updateButtonLabel(submitButtonRef, newLabel)
  }

  @undoable()
  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.SECONDS_TO_RESET_UPDATED })
  public setComponentConnectionResetUpdated(
    connectToRef: ComponentRef,
    connectionConfig,
    _biData = {}
  ) {
    return this.coreApi.setComponentConnection(connectToRef, connectionConfig)
  }

  public getTags() {
    return this.remoteApi.getTags()
  }

  @undoable()
  public async addSubmitButton(componentRef: ComponentRef) {
    const get = async () => {
      const {
        controllerRef,
        config: { theme, preset },
      } = await this.coreApi.getComponentConnection(componentRef)
      const boxLayout = await this.boundEditorSDK.components.layout.get({ componentRef })
      const locale = await this.boundEditorSDK.info.getLanguage()
      return {
        controllerRef,
        preset,
        boxLayout,
        locale,
        theme,
      }
    }

    const update = async (
      presetKey: FormPreset,
      controllerRef,
      boxLayout,
      locale,
      theme: Theme
    ) => {
      const layout = { layout: { y: boxLayout.height - SUBMIT.BUTTON_HEIGHT } }
      const buttonType = (await this.coreApi.isRegistrationForm(componentRef))
        ? ButtonType.SIGNUP
        : ButtonType.SUBMIT

      const submitButton = await createSubmitButton(
        { layout, buttonType },
        {
          presetKey,
          locale,
          onFailedPresetCallback: reason => this.coreApi.logFetchPresetsFailed(null, reason),
        }
      )

      const { connectToRef } = await this.coreApi.addComponentAndConnect(
        submitButton,
        controllerRef,
        componentRef
      )
      if (theme) {
        this.coreApi.style.updateComponentTheme(connectToRef, theme)
      }
      const fieldLayout = await this.boundEditorSDK.components.layout.get({
        componentRef: connectToRef,
      })

      const updateBoxLayout = async boxRef => {
        const boxLayout = await this.boundEditorSDK.components.layout.get({ componentRef: boxRef })
        return this.boundEditorSDK.components.layout.update({
          componentRef: boxRef,
          layout: { height: boxLayout.height + fieldLayout.height + 32 },
        })
      }

      const updateAncestorsBoxLayout = () =>
        this.boundEditorSDK.components
          .getAncestors({ componentRef })
          .then(ancestors => Promise.all(_.map(ancestors, updateBoxLayout)))

      // tslint:disable-next-line:early-exit
      if (await this.coreApi.isRegistrationForm(componentRef)) {
        await Promise.all([updateBoxLayout(componentRef), updateAncestorsBoxLayout()]).then(() =>
          this.coreApi.layout.centerComponentInsideLightbox(componentRef)
        )
      }
    }

    const updateMessageLocation = async () => {
      const messages = await this.coreApi.layout.getChildrenLayouts(componentRef, ROLE_MESSAGE)
      const message: any = messages[0]
      if (!message) {
        return null
      }
      return this.boundEditorSDK.components.layout.update({
        componentRef: message.componentRef,
        layout: { y: message.y + SUBMIT.MESSAGE },
      })
    }

    const {
      controllerRef,
      boxLayout,
      preset,
      locale,
      theme,
    } = await get()
    if (!boxLayout) {
      return null
    }
    await update(preset, controllerRef, boxLayout, locale, theme)
    return updateMessageLocation()
  }

  public async getMessage(componentRef: ComponentRef) {
    const messageRef = await this.coreApi.findComponentByRole(componentRef, ROLE_MESSAGE)
    if (!messageRef) {
      return { message: '' }
    }
    const data = await this.boundEditorSDK.components.data.get({ componentRef: messageRef })
    return { message: innerText(data.text) }
  }

  public async getEmails(componentRef) {
    const {
      config: { emailId, secondEmailId },
    } = await this.coreApi.getComponentConnection(componentRef)
    return Promise.all([
      this.remoteApi.getEmailById(emailId),
      this.remoteApi.getEmailById(secondEmailId),
    ])
  }

  public async getCrucialElements(componentRef: ComponentRef) {
    const { controllerRef }: ComponentConnection = await this.coreApi.getComponentConnection(
      componentRef
    )

    const [
      submitButtonRef,
      messageRef,
      isEmailFieldMissing,
      {
        config: { successActionType },
      },
    ] = await Promise.all([
      // TODO: you can merge all of it to one call (componentRef, [ROLE_SUBMIT_BUTTON, ROLE_MESSAGE]) and filter locally
      this.coreApi.findComponentByRole(componentRef, ROLE_SUBMIT_BUTTON),
      this.coreApi.findComponentByRole(componentRef, ROLE_MESSAGE),
      this.coreApi.isEmailFieldMissing(controllerRef),
      this.coreApi.getComponentConnection(componentRef),
    ])
    return {
      isSubmitButtonExists: !!submitButtonRef,
      isHiddenMessageExists:
        successActionType === SuccessActionTypes.LINK ||
        successActionType === SuccessActionTypes.DOWNLOAD_DOCUMENT ||
        successActionType === SuccessActionTypes.EXTERNAL_LINK ||
        !!messageRef,
      isEmailFieldMissing,
    }
  }

  public async getOtherFormsNames(componentRef: ComponentRef): Promise<string[]> {
    const { controllerRef } = await this.coreApi.getComponentConnection(componentRef)
    const controllers = _.map(
      await this.boundEditorSDK.controllers.listAllControllers(),
      ({ controllerRef }) => controllerRef
    )
    return await Promise.all(
      _.map(_.pullAllBy(controllers, [controllerRef], 'id'), async formControllerRef => {
        const formRef = await this.coreApi.findConnectedComponent(formControllerRef, ROLE_FORM)
        if (!formRef) {
          return ''
        }
        const {
          config: { formName },
        } = await this.coreApi.getComponentConnection(formRef)
        return formName
      })
    )
  }

  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public async updateFormName(
    connectToRef: ComponentRef,
    { formName }: { formName: string },
    _biData = {}
  ) {
    await this.coreApi.setComponentConnection(connectToRef, { formName })
    const {
      config: { formLabelId, labels },
    } = await this.coreApi.getComponentConnection(connectToRef)
    if (formLabelId && _.includes(labels, formLabelId)) {
      await this.remoteApi.updateTag(formLabelId, formName)
    }
  }

  private async _addHiddenMessage(componentRef: ComponentRef, newSuccessMessage?) {
    const get = async () => {
      const getBoxLayout = async componentRef => {
        const boxLayout = await this.boundEditorSDK.components.layout.get({ componentRef })
        return Promise.resolve(boxLayout)
      }

      const boxLayout = await getBoxLayout(componentRef)
      const {
        controllerRef,
        config: { preset },
      } = await this.coreApi.getComponentConnection(componentRef)
      const locale = await this.boundEditorSDK.info.getLanguage()
      return { boxLayout, controllerRef, locale, preset }
    }

    const update = async (
      boxLayout,
      controllerRef,
      locale,
      presetKey
    ) => {
      const layout = { y: boxLayout.height - HIDDEN_MESSAGE.HIDDEN_MESSAGE_HEIGHT }
      const hiddenMessageType = (await this.coreApi.isRegistrationForm(componentRef))
        ? MessageType.REGISTRATION
        : MessageType.HIDDEN

      const hiddenMessage = await createHiddenMessage(
        { layout, hiddenMessageType, newSuccessMessage },
        {
          presetKey,
          locale,
          onFailedPresetCallback: reason => this.coreApi.logFetchPresetsFailed(null, reason),
        }
      )
      const { connectToRef } = await this.coreApi.addComponentAndConnect(
        hiddenMessage,
        controllerRef,
        componentRef
      )

      const fieldLayout = await this.boundEditorSDK.components.layout.get({
        componentRef: connectToRef,
      })

      const updateBoxLayout = async boxRef => {
        const boxLayout = await this.boundEditorSDK.components.layout.get({ componentRef: boxRef })
        return this.boundEditorSDK.components.layout.update({
          componentRef: boxRef,
          layout: { height: boxLayout.height + fieldLayout.height + 32 },
        })
      }

      const updateAncestorsBoxLayout = () =>
        this.boundEditorSDK.components
          .getAncestors({ componentRef })
          .then(ancestors => Promise.all(_.map(ancestors, updateBoxLayout)))

      // tslint:disable-next-line:early-exit
      if (await this.coreApi.isRegistrationForm(componentRef)) {
        await Promise.all([updateBoxLayout(componentRef), updateAncestorsBoxLayout()]).then(() =>
          this.coreApi.layout.centerComponentInsideLightbox(componentRef)
        )
      }
    }

    const { boxLayout, controllerRef, locale, preset } = await get()
    return update(boxLayout, controllerRef, locale, preset)
  }

  private async _getLinkType(linkObject) {
    if (!_.get(linkObject, 'pageId')) {
      if (_.get(linkObject, 'url')) {
        return LinkTypes.EXTERNAL_LINK
      }
      return LinkTypes.NONE
    }

    const linkedPageRef = { type: 'DESKTOP', id: linkObject.pageId.substring(1) }
    const linkData = await this.boundEditorSDK.components.data.get({
      componentRef: linkedPageRef,
    })
    return _.get(linkData, 'isPopup') ? LinkTypes.LIGHTBOX : LinkTypes.PAGE
  }

  private _getLinkSubType(successLinkText, successLinkType, linkObject) {
    switch (successLinkType) {
      case LinkTypes.PAGE:
        return successLinkText
      case LinkTypes.LIGHTBOX:
        return linkObject.pageId
      case LinkTypes.EXTERNAL_LINK:
        return linkObject.url
      default:
        return null
    }
  }
}
