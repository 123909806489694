import * as _ from 'lodash'
import { FieldPreset } from '../../../../../../constants/field-types'
import translations from '../../../../services/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { iconNames } from '../../icons/types'
import { phoneData } from '../../recommended-fields/definitions/phone'

const makeAdiPhone = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return _.merge(phoneData.make(), {
    extraData: {
      data: {
        placeholder: t(`adi.fieldTypes.${FieldPreset.PHONE}.placeholder`),
        label: t(`adi.fieldTypes.${FieldPreset.PHONE}.label`),
      },
      props: { placeholder: t(`adi.fieldTypes.${FieldPreset.PHONE}.placeholder`) },
    },
  })
}

export const adiPhoneData: FieldData = {
  make: makeAdiPhone,
  customFields: [],
  icon: iconNames.phone,
}
